import { loadAllPolyfills } from './loadAllPolyfills';
import { MfeModule } from './types';

let coreMfeMountedPromiseResolve: () => void;

const coreMfeMountedPromise = new Promise<void>((resolve) => {
    coreMfeMountedPromiseResolve = resolve;
});

export async function loadCoreMfe() {
    const url = System.resolve('@landr/core-mfe');
    loadAllPolyfills();

    if (url.indexOf('app-v2.js') > 0) {
        const module = await import(/* webpackIgnore: true */ /* @vite-ignore */ url);
        return module?.load();
    }

    const { default: module } = await System.import<MfeModule>('@landr/core-mfe');
    return module?.load();
}

export function setIsCoreMfeMounted() {
    if (coreMfeMountedPromiseResolve) {
        coreMfeMountedPromiseResolve();
    }
}

export function waitForCoreMFeToBeMounted() {
    return coreMfeMountedPromise;
}
