import * as singleSpa from 'single-spa';
import { addErrorHandler, LifeCycles } from 'single-spa';
import { auth, User } from '@landr/root-auth';
import { Log } from '@landr/root-log';
import { logFactory, LogFactoryOptions } from './log';
import { featureFlagService } from '@landr/root-feature-flags';
import { Config, loadConfig } from './config';
import { EventIds } from './constants/EventIds';
import { AbstractSegmentServiceV2, AnalyticsApplication, Environment, initCalmResizeObserver } from '@landr/core';
import { loadCoreMfe, setIsCoreMfeMounted, waitForCoreMFeToBeMounted } from './coreMfeUtils';
import { getProfileMfeActivityFn, isProfileMfeLocation } from './activityFunctions';

declare global {
    interface Window {
        // In case we use prerender, we'll need to skip this
        prerenderCloudIsServerSideRendering?: boolean;
        prerendercloudReady?: boolean;
        LANDR_NETWORK_CONFIG: Config;
        LANDR_NETWORK_LOG_FACTORY: (options: LogFactoryOptions) => Log;
    }
}

// Any change in this object should be done to all root apps (Network and Webapp)
const ExternalDependencies = {
    'styled-components': 'StyledComponents',
    react: 'React',
    'react-dom': 'ReactDom',
    'react-is': 'ReactIs',
    'single-spa': 'SingleSpa',
};

/**
 * Used by vite js build to share external dependencies
 */
const injectExternalDependencies = (log: Log) => {
    const promises = Object.entries(ExternalDependencies).map(([dependency, variableName]) => {
        return System.import(dependency)
            .then((value) => {
                if (!(variableName in window)) {
                    if ('default' in value) {
                        value = Object.assign(value.default, value);
                    }
                    (window as any)[variableName] = value;
                }
            })
            .catch((error) => {
                log.error(`Failed to load dependency ${variableName}`, EventIds.ExternalDependencyError, error);
                throw error;
            });
    });

    return Promise.all(promises);
};

function getOfflineUrl(config: Config): string {
    return `${config.offlineUrl}?returnUrl=${encodeURIComponent(window.location.href)}`;
}

function redirectToOfflinePage(config: Config) {
    setTimeout(() => {
        window.location.assign(getOfflineUrl(config));
    }, 2000);
}

/**
 * Will add red border box when user is logged in on behalf through support admin
 */
const checkLoggedOnBehalf = (user: User | null) => {
    if (user && user.isLoggedOnBehalf) {
        const htmlEl = document.querySelector('html');
        if (htmlEl) {
            htmlEl.className = 'on-behalf';
        }
    }
};

/**
 * This is loading analytics
 */
export const loadAnalytics = (config: Config, analytics: AbstractSegmentServiceV2, user: User | null): void => {
    // We don't load analytics if it's prerendered, as it doesn't make sense to have analytics for bots
    if (window.prerenderCloudIsServerSideRendering) {
        return;
    }

    // We don't load analytics if the user is logged in on behalf
    if (user?.isLoggedOnBehalf) {
        return;
    }

    analytics.load(config.networkSegmentWriteKey);
};

const loadDevTools = (config: Config) => {
    // Preload the devtools with a Network app. The devtools will crash
    // if this is not done before feature flag service's .load() method is called.
    try {
        window?.__LANDR_DEVTOOLS_HOOKS__?.emit?.('set-state', {
            name: config.appName,
            version: config.appVersion,
            config: Object.fromEntries(
                Object.entries(config)
                    // We can only show a flat tree in dev tools.
                    .map(([key, value]) => [key, JSON.stringify(value) ?? '⚠️ Unserializable object.']),
            ),
        });
    } catch (e) {
        // This is a development tool, we can safely ignore errors from dev tools.
        console.warn('landr-root-config.ts in Root App', e);
    }
};

/**
 * Sets lang attribute to <html> tag on mfes
 */
function setLangAttribute(user: User | null) {
    const lang = user?.preferredCulture?.split('-')[0] ?? 'en';
    const htmlEl = document.querySelector('html');

    if (htmlEl) {
        htmlEl.setAttribute('lang', lang);
    }
}

const hideLoader = () => {
    const loadingContainer = document.getElementById('loading-container');
    if (!loadingContainer) {
        return;
    }

    loadingContainer.style.display = 'none';
};
export function importViteModule(packageName: string) {
    const url = System.resolve(packageName);

    return import(/* webpackIgnore: true */ url);
}

const registerApplications = (coreMfeLoadPromise: Promise<LifeCycles>): void => {
    singleSpa.registerApplication({
        name: 'core-mfe',
        app: () => coreMfeLoadPromise,
        activeWhen: () => true,
    });

    singleSpa.registerApplication({
        name: '@landr/profile-mfe',
        app: async () => {
            const module = await importViteModule('@landr/profile-mfe');
            await waitForCoreMFeToBeMounted();
            return module.load();
        },
        activeWhen: (location) => isProfileMfeLocation(location),
    });

    singleSpa.registerApplication({
        name: '@landr/network-angular-mfe',
        app: () =>
            System.import('@landr/network-angular-mfe').then(({ default: module }) => {
                return coreMfeLoadPromise.then(waitForCoreMFeToBeMounted).then(() => module.load());
            }),
        activeWhen: (location) => !isProfileMfeLocation(location),
    });
};

function setupErrorHandling(log: Log, config: Config): void {
    // Single SPA error
    addErrorHandler((error: Error) => {
        const level = error.message.match(/LOADING_SOURCE_CODE/) ? 'error' : 'fatal';
        log[level]('Lifecycle or activity function error in single-spa', EventIds.SingleSpaError, error);

        if (error && (error as any).appOrParcelName === 'core-mfe') {
            redirectToOfflinePage(config);
        } else {
            hideLoader();
        }
    });

    // General window error
    window.addEventListener('error', (event) => {
        log.error(`Unhandled error: ${event.message}`, EventIds.UnhandledError, event.error, { event });
    });
    window.addEventListener('unhandledrejection', (event) => {
        log.error(`Unhandled rejection: ${event.reason}`, EventIds.UnhandledError);
    });
}

async function bootstrap(): Promise<void> {
    // Notify prerender that we are still loading
    window.prerendercloudReady = false;

    // Bootstrap config
    const config = await loadConfig();
    window.LANDR_NETWORK_CONFIG = Object.freeze(config);

    // Based on config, bootstrap root app logs
    const logRootAppConfig = {
        serviceKey: 'NetworkRootApp',
        tags: 'Web,MFE,NetworkRootApp',
    };
    const log = logFactory(config, null)(logRootAppConfig);

    // Inject external dependencies for ViteJS builds
    const dependencyInjectionPromise = injectExternalDependencies(log);
    // Preload core-mfe before anything as we're sure it needs to be loaded
    const coreMfeLoadPromise = dependencyInjectionPromise.then(loadCoreMfe);

    setupErrorHandling(log, config);

    // IMPORTANT: this is loading authentication client
    try {
        initCalmResizeObserver();
        loadDevTools(config);

        // Callback from Stripe is using 'code' and 'state' as URL params, which
        // will make the Auth authentication failed and Stripe will failed also.
        // We need to change location at bootstrap
        const urlParams = new URLSearchParams(window.location.search);
        const isCallbackFromStripeConnect = window.location.pathname === '/users/me/connect';
        const stripeCode = urlParams.get('code');
        const stripeState = urlParams.get('state');
        if (isCallbackFromStripeConnect && stripeCode && stripeState) {
            const params = new URLSearchParams();
            params.set('stripeCode', stripeCode);
            params.set('stripeState', stripeState);
            window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
        }

        // Create analytics that will NOT be init in some cases (prerender, loggin on behalf)
        const analyticsV2 = new AbstractSegmentServiceV2(AnalyticsApplication.Network, window);

        // Init authentication
        const environment = config.landrEnv?.toLowerCase() as Environment;
        const cookieSettings = {
            secure: true,
            expires: 30, // in days
            domain: '.landr.com',
        };
        await auth.initialize({
            domain: config.fusionAuthDomain,
            clientId: config.fusionAuthClientId,
            environment,
            log,
            analytics: analyticsV2,
            cookieSettings,
            // This is the default value used for amplitude user attribute `L - Signed Up Home Page`.
            homepageForAnalyticsFallback: 'Network',
        });

        // Get user
        const user = await auth.getUser();
        checkLoggedOnBehalf(user);
        setLangAttribute(user);

        // Load services
        loadAnalytics(config, analyticsV2, user);
        await featureFlagService.load(config.featureFlagApiBaseUrl, config.appName, config.appVersion, log, auth);

        // Register log factory with current config and user
        // Can be used by other MFEs and libraries
        window.LANDR_NETWORK_LOG_FACTORY = logFactory(config, user);

        // Loader event
        const onAppChange = (event: any) => {
            if (event.detail?.appsByNewStatus?.MOUNTED.length > 1) {
                hideLoader();
                // Unregister event listener, as the loader is hidden once
                window.removeEventListener('single-spa:app-change', onAppChange);
            }
        };
        window.addEventListener('single-spa:app-change', onAppChange);

        // Wait for core-mfe to be mounted
        const onFirstMount = () => {
            setIsCoreMfeMounted();
            window.removeEventListener('single-spa:app-change', onFirstMount);
        };
        window.addEventListener('single-spa:first-mount', onFirstMount);

        // Then... finally.... register and display the app
        try {
            await dependencyInjectionPromise;
        } catch (e) {
            log.error('Failed to load external dependencies', EventIds.ExternalDependencyError, e);
            redirectToOfflinePage(config);
            return;
        }
        registerApplications(coreMfeLoadPromise);
        singleSpa.start({
            urlRerouteOnly: true,
        });
    } catch (e) {
        log.fatal('Failed to init authentication', EventIds.AppBootstrapError, e);
    }
}

bootstrap();
