export function loadAllPolyfills(): void {
    loadFromEntriesPolyfill();
    loadGlobalThisPolyfill();
    loadAggregateErrorPolyfill();
}

function loadFromEntriesPolyfill() {
    // CHFE-1159: Object.fromentries polyfill from https://github.com/feross/fromentries/blob/master/index.js
    if (!Object.fromEntries) {
        Object.defineProperty(Object, 'fromEntries', {
            value<T = any>(entries: Iterable<readonly [string | number, T]>) {
                if (!entries || !entries[Symbol.iterator]) {
                    throw new Error('Object.fromEntries() requires a single iterable argument');
                }

                return [...entries].reduce<Record<string | number, T>>((obj, [key, val]) => {
                    obj[key] = val;
                    return obj;
                }, {});
            },
        });
    }
}

function loadGlobalThisPolyfill() {
    require('@ungap/global-this');
}

function loadAggregateErrorPolyfill() {
    if (typeof AggregateError === 'undefined') {
        // eslint-disable-next-line no-global-assign
        globalThis.AggregateError = class AggregateError extends Error {
            constructor(public errors: Iterable<any>, public message: string) {
                super(message);
                this.errors = errors;
            }
        } as any;
    }
}
