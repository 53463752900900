(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("single-spa"), require("@landr/root-auth"), require("@landr/root-log"), require("@landr/root-feature-flags"));
	else if(typeof define === 'function' && define.amd)
		define(["single-spa", "@landr/root-auth", "@landr/root-log", "@landr/root-feature-flags"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("single-spa"), require("@landr/root-auth"), require("@landr/root-log"), require("@landr/root-feature-flags")) : factory(root["single-spa"], root["@landr/root-auth"], root["@landr/root-log"], root["@landr/root-feature-flags"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__589__, __WEBPACK_EXTERNAL_MODULE__109__, __WEBPACK_EXTERNAL_MODULE__820__, __WEBPACK_EXTERNAL_MODULE__50__) {
return 