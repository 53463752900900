import { LogLevel } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';

export type ConfigLogOptions = {
    enabled: boolean;
    separator: string;
    console: {
        enabled: boolean;
        minLevel: LogLevel;
    };
    loggly: {
        enabled: boolean;
        apiKey: string;
        minLevel: LogLevel;
        maxBundleSize: number;
        consecutiveOccurrences: number;
    };
};

export type Config = {
    appName: ApplicationEnum;
    appVersion: string;

    offlineUrl: string;

    mainAppUrl: string;
    landrEnv: string;

    networkAppUrl: string;
    networkApiBaseUrl: string;
    networkStripePublishableKey: string;
    networkSegmentWriteKey: string;

    fusionAuthDomain: string;
    fusionAuthClientId: string;

    msUserApiBaseUrl: string;
    msProjectsApiBaseUrl: string;
    monolithApiHost: string;
    featureFlagApiBaseUrl: string;

    recaptchaSiteKey: string;

    logOptions: ConfigLogOptions;
};

export const loadConfig = async (): Promise<Config> => {
    const importConfig = (await import(
        /* webpackChunkName: "config" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        './config.json'
    )) as any;
    const config = importConfig.default as Config;

    // Override with local settings
    if (process.env.NODE_ENV === 'local') {
        const targetEnv = process.env.TARGET_ENV;
        const isDemo = targetEnv === 'demo';
        config.mainAppUrl = `https://app-${targetEnv}.landr.com/`;
        config.landrEnv = isDemo ? 'Demo' : 'Development';
        config.appVersion = 'NOT a deployable build!';

        // Network app
        config.networkAppUrl = 'https://local.landr.com:8080/';
        config.networkApiBaseUrl = `https://local.landr.com:8080/api`;
        config.networkSegmentWriteKey = 'd9x3CqTBnxZxDCpk5HiyVlyoSaUzvFD6';
        config.networkStripePublishableKey = 'pk_test_tZiIHeekNeAHzjp5D837Y3r500R3hN9z4Q';

        // FusionAuth
        config.fusionAuthDomain = isDemo ? 'https://accounts-demo.landr.com' : 'https://accounts-dev.landr.com';
        config.fusionAuthClientId = isDemo
            ? 'eb4e2915-ed2a-46cc-b539-41f66d7109ff'
            : '356d0197-b80e-4ba5-aa2d-90a6d1eefd78';

        // Base URLs
        config.monolithApiHost = `https://api-${targetEnv}.landr.com`;
        config.msUserApiBaseUrl = `https://api3-${targetEnv}.landr.com/user`;
        config.msProjectsApiBaseUrl = `https://api3-${targetEnv}.landr.com/projects`;
        config.featureFlagApiBaseUrl = `https://api3-${targetEnv}.landr.com/featureflag`;

        // Recaptcha
        config.recaptchaSiteKey = isDemo
            ? '6LfFBLcZAAAAAL6f5x3-wQMdWhh1new2RRjJiDog'
            : '6Ld8BLcZAAAAAJP0e3PQp-uhBNQveqdV91UGkfeM';

        // Logs
        config.logOptions.console.minLevel = LogLevel.DEBUG;
        config.logOptions.loggly.enabled = false;
    }

    return config;
};
